import React from "react";
import { graphql } from "gatsby";
import * as styles from "./PageHero.module.scss";
import { IStaticImageProps } from "gatsby-plugin-image/dist/src/components/static-image.server";
import { Section } from "./Section";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import { getContactUrl } from "../utils/urls";
import { Button } from "./Button";

type PageHeroWrapProps = { className?: string };

export const PageHeroWrap: React.FC<PageHeroWrapProps> = ({
  className,
  children,
}) => {
  return (
    <Section containerOnly className={cn(styles.wrap, className)}>
      {children}
    </Section>
  );
};

type PageHeroContactProps = {
  why: React.ReactNode;
  cta?: React.ReactNode;
  className?: string;
};

export const PageHeroContact: React.FC<PageHeroContactProps> = ({
  why,
  cta,
  className,
}) => {
  const [contactRef, contactInView] = useInView({ triggerOnce: true });

  return (
    <div
      ref={contactRef}
      className={cn(styles.contact, className, {
        visible: contactInView,
      })}
    >
      <p>{why}</p>
      <Button variant="cta" to={getContactUrl()}>
        {cta || "Get in Touch"}
      </Button>
    </div>
  );
};

type PageHeroProps = {
  pageTitle: React.ReactNode;
  pageCta?: React.ReactNode;
  image?: React.ReactNode;
  embed?: () => React.ReactNode;
  className?: string;
};

export const PageHero: React.FC<PageHeroProps> = ({
  pageTitle,
  pageCta,
  children,
  image,
  embed,
  className,
}) => {
  return (
    <div className={cn(styles.hero, className)}>
      <div className={styles.title}>{pageTitle}</div>

      {image && <div className={styles.imgwrap}>{image}</div>}
      {embed && (
        <div className={cn(styles.imgwrap, styles.embed)}>{embed()}</div>
      )}

      <div className={styles.content}>{children}</div>
      {pageCta && <div className={styles.cta}> {pageCta}</div>}
    </div>
  );
};

export const heroImageProps: Partial<IStaticImageProps> = {
  width: 546,
  height: 600,
  transformOptions: { fit: "cover", cropFocus: "ENTROPY" },
  quality: 90,
  formats: ["auto", "webp"],
};

export const heroQuery = graphql`
  fragment HeroImgData on File {
    childImageSharp {
      gatsbyImageData(
        width: 546
        height: 600
        transformOptions: { fit: COVER, cropFocus: ENTROPY }
        quality: 90
        formats: [AUTO, WEBP]
      )
    }
  }
`;
