import React from "react";
import { Layout } from "../components/Layout";

import notFoundImg from "../images/404.svg";
import { Seo } from "../components/Seo";
import { getHomeUrl } from "../utils/urls";
import { PageHeroWrap } from "../components/PageHero";
import * as styles from "./404.module.scss";
import { Button } from "../components/Button";

const Page: React.FC = () => (
  <Layout>
    <Seo
      ogFullTitle
      title="Page Not Found"
      description="You just hit an URL that doesn`t exist... the sadness."
    />
    <PageHeroWrap className={styles.hero}>
      <img alt="Not found" src={notFoundImg} className="" />
      <h1>NOT FOUND</h1>
      <p>You just hit an URL that doesn&#39;t exist... the sadness.</p>
      <Button variant="cta" to={getHomeUrl()}>
        Go Home
      </Button>
    </PageHeroWrap>
  </Layout>
);

export default Page;
